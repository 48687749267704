.relative {
  position: relative;
}

.mobile-nav {
  display: inline-block;
  padding-left: 15px;
  padding-right: 0;
  vertical-align: middle;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  -webkit-transition-property: background, color, height;
  transition-property: background, color, height;
}

.mobile-nav:hover,
.mobile-nav.active {
  opacity: 0.75;
}

.mobile-nav:active {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.05) inset;
}

.main-nav {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 85px !important;
  top: 0;
  left: 0;
  height: 70px !important;
  line-height: 85px !important;
  text-align: left;
  background: rgba(255, 255, 255, 0.99);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
  z-index: 1030;
  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
}

.main-nav.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.main-nav.dark .mobile-nav {
  background-color: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.9);
}

.main-nav.dark {
  background-color: #00000000;
}

.main-nav.dark .logo,
.main-nav.dark a.logo:hover {
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.main-nav.transparent {
  background: transparent !important;
  box-shadow: none;
}

.main-nav.js-transparent {
  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
}
.main-nav.dark {
  background-color: rgba(10, 10, 10, 0.95);
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.nav__cta_big.btn-mod.btn-border {
  color: #ffffff;
  border: 2px solid #ffffff;
  background: transparent;
  border-radius: 6px;
  opacity: 0.8;
}

.logo,
a.logo:hover {
  font-size: 18px;
  font-weight: 600 !important;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
}

.full-wrapper {
  margin: 0 2%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1366px) {
  .full-wrapper {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .full-wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.nav-logo-wrap {
  float: left;
  margin-right: 20px;
}

.nav-logo-wrap .logo {
  display: flex;
  align-items: center;
  max-width: 188px;
  height: 85px;
  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
}

.nav-logo-wrap .logo img {
  max-height: 100%;
}

.nav-logo-wrap .logo:before,
.nav-logo-wrap .logo:after {
  display: none;
}

@media only screen and (max-width: 480px) {
  .nav__logo-text {
    font-size: 26px;
  }

  .nav__cta_big.btn-mod.btn-border {
    padding: 8px 11px 10px 11px;
  }
}

@media only screen and (max-width: 1024px) {
  .main-nav,
  .main-nav.small-height,
  .nav-logo-wrap .logo,
  .nav-logo-wrap .logo.small-height {
    height: 70px !important;
    line-height: 80px !important;
  }
}

@media only screen and (max-width: 767px) {
  .nav-logo-wrap .logo {
    max-width: 150px;
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
