.footer {
    text-align: center;
    font-size: 18px;
    padding-top: 140px;
    padding-bottom: 100px;
}

.footer a {
    text-decoration: none;
    -webkit-transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
    -o-transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.footer a:hover {
    color: #111;
    text-decoration: underline;
}

.footer__copyright {
    margin-bottom: 2px;
    font-weight: 600;
    color: #171717;
    opacity: 0.9;
}

.footer__made {
    font-size: 14px;
    font-weight: 400;
    color: #999;
}

.footer__social-links {
    font-size: 16px;
}

.footer__social-links a {
    width: 54px;
    height: 54px;
    line-height: 54px !important;
    position: relative;
    margin: 0 2px;
    text-align: center;
    display: inline-block;
    color: #fff;
    opacity: 0.85;
    overflow: hidden;
    -webkit-transition: all 0.23s cubic-bezier(0.3, 0.1, 0.58, 1);
    -o-transition: all 0.23s cubic-bezier(0.3, 0.1, 0.58, 1);
    transition: all 0.23s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.footer__social-links a:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #111;
    top: 0;
    left: 0;
    border-radius: 50%;
    -webkit-transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
    -o-transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.footer__social-links a i,
.footer__social-links a span[class^="icon"] {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    line-height: 54px;
}

.footer__social-links a:hover {
    color: #fff;
    opacity: 1;
    text-decoration: none;
}

.footer__social-links a:hover:before {
    background: #000;
}

.footer__logo {
    width: 160px;
    height: 160px;
    background: white;
    border-radius: 50%;
    position: absolute;
    bottom: 97px;
    right: 30px;
    border: 2px solid #fff;
    -webkit-box-shadow: 0 3px 5px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 10%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.footer__logo :hover {
    -webkit-box-shadow: 0 5px 7px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 5px 7px 0 rgb(0 0 0 / 20%);
}

@media only screen and (max-width: 1024px) {
    .footer {
        padding-top: 52px;
    }

    .footer__logo {
        width: 60px;
        height: 60px;
    }
}

@media only screen and (max-width: 388px) {
    .footer__text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .footer__logo {
        right: 20px;
    }
}