.about__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: baseline;
}

.about__edit_dark {
    -webkit-filter: invert(0.5);
    filter: invert(0.5);
    padding: 4px 8px 4px 8px;
    cursor: pointer;
}

.about__heading {
    margin-bottom: 30px;
}

.about__button {
    background-image: url("../../images/icons/edit.svg");
    background-color: transparent;
    border: none;
    height: 15px;
    width: 15px;
    background-repeat: no-repeat;
    cursor: pointer;
}

@media only screen and (max-width: 480px) .about__heading {
    .about__heading {
        margin-bottom: 10px;
    }
}