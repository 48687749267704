.social-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.social-input__icon {
  margin: 9px 14px 0 0;
}

.social-input__field {
  max-width: 358px;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 13px 0;
  margin-bottom: 17px;
  color: #000000;
}