.profile__banner {
  padding: 0;
  padding-top: 0;
  color: #fff;
}

.profile__banner a {
  color: rgba(255, 255, 255, 0.8);
}

.profile__banner a:hover {
  color: #fff;
}

.profile__container {
  margin-top: 96px;
  padding: 30px 30px;
  position: relative;
  z-index: 1;
}

.profile__backdrop {
  width: 100%;
  height: 300px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.profile__background-image {
  width: 100%;
  height: 48vmax;
  object-fit: cover;
  position: fixed;
}

.profile__header {
  background-color: #000;
  border-radius: 20px;
  -webkit-box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  -webkit-transition: 0.03s;
  -o-transition: 0.03s;
  transition: 0.03s;
  gap: 40px;
}

.profile__text-content {
  padding: 10px 10px 10px 0;
  width: 48%;
}

.profile__image-container {
  min-width: 48%;
  max-width: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transition: 0.03s;
  -o-transition: 0.03s;
  transition: 0.03s;
}

.profile__header,
.profile__image-container {
  height: 48vmax;
  min-height: 250px;
  max-height: 600px;
}

.profile__firstname {
  font-size: 52px;
  line-height: 1.4;
  margin: 0;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.profile__lastname {
  font-size: 52px;
  line-height: 1.4;
  margin: 0;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.profile__title {
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: pre-wrap;
  /* css-3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap;
  /* Newer versions of Chrome/Safari*/
  /*word-break: break-all;*/
  white-space: normal;
}

.profile__map-pin {
  width: 16px;
  -webkit-filter: invert(0.5);
  filter: invert(0.5);
  margin-top: -4px;
  margin-right: 7px;
}

.profile__breadcrumbs-dot {
  color: #ffffff87;
}


.profile__edit_light {
  position: absolute;
  top: 21px;
  right: 26px;
  color: #000;
  padding: 4px 8px 4px 8px;
  cursor: pointer;
}

.profile__image-input {
  max-width: 358px;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 13px 0;
  margin-bottom: 17px;
  color: #000000;
}

@media only screen and (max-width: 980px) {

  .profile__header,
  .profile__image-container {
    height: 40vmax;
  }

  .profile__background-image {
    height: 44vmax;
  }
}

@media only screen and (max-width: 767px) {
  .page {
    font-size: 14px;
    line-height: 1.4;
  }

  .profile__header {
    gap: 20px;
  }

  .profile__header,
  .profile__image-container {
    height: 32vmax;
  }

  .profile__firstname {
    font-size: 42px;
  }

  .profile__lastname {
    font-size: 42px;
  }
}

@media only screen and (max-width: 540px) {
  .profile__firstname {
    font-size: 38px;
  }

  .profile__lastname {
    font-size: 38px;
  }
}

@media only screen and (max-width: 480px) {
  .profile__header {
    gap: 10px;
  }

  .profile__container {
    padding: 30px 20px;
  }

  .profile__edit_light {
    top: 4px;
    right: 10px;
  }

  .profile__header,
  .profile__image-container {
    height: 28vmax;
    min-height: 200px;
  }

  .profile__image-container {
    min-width: 44%;
  }

  .profile__text-content {
    width: 56%;
  }

  .profile__firstname {
    font-size: 28px;
  }

  .profile__lastname {
    font-size: 28px;
  }

  .profile__title {
    font-size: 16px;
  }

  .profile__location {
    font-size: 14px;
  }

  .profile__map-pin {
    width: 14px;
  }
}



@media only screen and (max-width: 293px) {
  .profile__header {
    width: 100%;
  }
}