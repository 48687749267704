.mt-0 {
    margin-top: 0 !important
}

.mt-10 {
    margin-top: 10px !important
}

.mt-20 {
    margin-top: 20px !important
}

.mt-30 {
    margin-top: 30px !important
}

.mt-40 {
    margin-top: 40px !important
}

.mt-50 {
    margin-top: 50px !important
}

.mt-60 {
    margin-top: 60px !important
}

.mt-70 {
    margin-top: 70px !important
}

.mt-80 {
    margin-top: 80px !important
}

.mt-90 {
    margin-top: 90px !important
}

.mt-100 {
    margin-top: 100px !important
}

.mt-110 {
    margin-top: 110px !important
}

.mt-120 {
    margin-top: 120px !important
}

.mt-130 {
    margin-top: 130px !important
}

.mt-140 {
    margin-top: 140px !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-10 {
    margin-bottom: 10px !important
}

.mb-20 {
    margin-bottom: 20px !important
}

.mb-30 {
    margin-bottom: 30px !important
}

.mb-40 {
    margin-bottom: 40px !important
}

.mb-50 {
    margin-bottom: 50px !important
}

.mb-60 {
    margin-bottom: 60px !important
}

.mb-70 {
    margin-bottom: 70px !important
}

.mb-80 {
    margin-bottom: 80px !important
}

.mb-90 {
    margin-bottom: 90px !important
}

.mb-100 {
    margin-bottom: 100px !important
}

.mb-110 {
    margin-bottom: 110px !important
}

.mb-120 {
    margin-bottom: 120px !important
}

.mb-130 {
    margin-bottom: 130px !important
}

.mb-140 {
    margin-bottom: 140px !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-10 {
    padding-top: 10px !important
}

.pt-20 {
    padding-top: 20px !important
}

.pt-30 {
    padding-top: 30px !important
}

.pt-40 {
    padding-top: 40px !important
}

.pt-50 {
    padding-top: 50px !important
}

.pt-60 {
    padding-top: 60px !important
}

.pt-70 {
    padding-top: 70px !important
}

.pt-80 {
    padding-top: 80px !important
}

.pt-90 {
    padding-top: 90px !important
}

.pt-100 {
    padding-top: 100px !important
}

.pt-110 {
    padding-top: 110px !important
}

.pt-120 {
    padding-top: 120px !important
}

.pt-130 {
    padding-top: 130px !important
}

.pt-140 {
    padding-top: 140px !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-10 {
    padding-bottom: 10px !important
}

.pb-20 {
    padding-bottom: 20px !important
}

.pb-30 {
    padding-bottom: 30px !important
}

.pb-40 {
    padding-bottom: 40px !important
}

.pb-50 {
    padding-bottom: 50px !important
}

.pb-60 {
    padding-bottom: 60px !important
}

.pb-70 {
    padding-bottom: 70px !important
}

.pb-80 {
    padding-bottom: 80px !important
}

.pb-90 {
    padding-bottom: 90px !important
}

.pb-100 {
    padding-bottom: 100px !important
}

.pb-110 {
    padding-bottom: 110px !important
}

.pb-120 {
    padding-bottom: 120px !important
}

.pb-130 {
    padding-bottom: 130px !important
}

.pb-140 {
    padding-bottom: 140px !important
}

@media only screen and (max-width:1199px) {
    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-10 {
        margin-top: 10px !important
    }

    .mt-lg-20 {
        margin-top: 20px !important
    }

    .mt-lg-30 {
        margin-top: 30px !important
    }

    .mt-lg-40 {
        margin-top: 40px !important
    }

    .mt-lg-50 {
        margin-top: 50px !important
    }

    .mt-lg-60 {
        margin-top: 60px !important
    }

    .mt-lg-70 {
        margin-top: 70px !important
    }

    .mt-lg-80 {
        margin-top: 80px !important
    }

    .mt-lg-90 {
        margin-top: 90px !important
    }

    .mt-lg-100 {
        margin-top: 100px !important
    }

    .mt-lg-110 {
        margin-top: 110px !important
    }

    .mt-lg-120 {
        margin-top: 120px !important
    }

    .mt-lg-130 {
        margin-top: 130px !important
    }

    .mt-lg-140 {
        margin-top: 140px !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-10 {
        margin-bottom: 10px !important
    }

    .mb-lg-20 {
        margin-bottom: 20px !important
    }

    .mb-lg-30 {
        margin-bottom: 30px !important
    }

    .mb-lg-40 {
        margin-bottom: 40px !important
    }

    .mb-lg-50 {
        margin-bottom: 50px !important
    }

    .mb-lg-60 {
        margin-bottom: 60px !important
    }

    .mb-lg-70 {
        margin-bottom: 70px !important
    }

    .mb-lg-80 {
        margin-bottom: 80px !important
    }

    .mb-lg-90 {
        margin-bottom: 90px !important
    }

    .mb-lg-100 {
        margin-bottom: 100px !important
    }

    .mb-lg-110 {
        margin-bottom: 110px !important
    }

    .mb-lg-120 {
        margin-bottom: 120px !important
    }

    .mb-lg-130 {
        margin-bottom: 130px !important
    }

    .mb-lg-140 {
        margin-bottom: 140px !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-10 {
        padding-top: 10px !important
    }

    .pt-lg-20 {
        padding-top: 20px !important
    }

    .pt-lg-30 {
        padding-top: 30px !important
    }

    .pt-lg-40 {
        padding-top: 40px !important
    }

    .pt-lg-50 {
        padding-top: 50px !important
    }

    .pt-lg-60 {
        padding-top: 60px !important
    }

    .pt-lg-70 {
        padding-top: 70px !important
    }

    .pt-lg-80 {
        padding-top: 80px !important
    }

    .pt-lg-90 {
        padding-top: 90px !important
    }

    .pt-lg-100 {
        padding-top: 100px !important
    }

    .pt-lg-110 {
        padding-top: 110px !important
    }

    .pt-lg-120 {
        padding-top: 120px !important
    }

    .pt-lg-130 {
        padding-top: 130px !important
    }

    .pt-lg-140 {
        padding-top: 140px !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-10 {
        padding-bottom: 10px !important
    }

    .pb-lg-20 {
        padding-bottom: 20px !important
    }

    .pb-lg-30 {
        padding-bottom: 30px !important
    }

    .pb-lg-40 {
        padding-bottom: 40px !important
    }

    .pb-lg-50 {
        padding-bottom: 50px !important
    }

    .pb-lg-60 {
        padding-bottom: 60px !important
    }

    .pb-lg-70 {
        padding-bottom: 70px !important
    }

    .pb-lg-80 {
        padding-bottom: 80px !important
    }

    .pb-lg-90 {
        padding-bottom: 90px !important
    }

    .pb-lg-100 {
        padding-bottom: 100px !important
    }

    .pb-lg-110 {
        padding-bottom: 110px !important
    }

    .pb-lg-120 {
        padding-bottom: 120px !important
    }

    .pb-lg-130 {
        padding-bottom: 130px !important
    }

    .pb-lg-140 {
        padding-bottom: 140px !important
    }
}

@media only screen and (max-width:991px) {
    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-10 {
        margin-top: 10px !important
    }

    .mt-md-20 {
        margin-top: 20px !important
    }

    .mt-md-30 {
        margin-top: 30px !important
    }

    .mt-md-40 {
        margin-top: 40px !important
    }

    .mt-md-50 {
        margin-top: 50px !important
    }

    .mt-md-60 {
        margin-top: 60px !important
    }

    .mt-md-70 {
        margin-top: 70px !important
    }

    .mt-md-80 {
        margin-top: 80px !important
    }

    .mt-md-90 {
        margin-top: 90px !important
    }

    .mt-md-100 {
        margin-top: 100px !important
    }

    .mt-md-110 {
        margin-top: 110px !important
    }

    .mt-md-120 {
        margin-top: 120px !important
    }

    .mt-md-130 {
        margin-top: 130px !important
    }

    .mt-md-140 {
        margin-top: 140px !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-10 {
        margin-bottom: 10px !important
    }

    .mb-md-20 {
        margin-bottom: 20px !important
    }

    .mb-md-30 {
        margin-bottom: 30px !important
    }

    .mb-md-40 {
        margin-bottom: 40px !important
    }

    .mb-md-50 {
        margin-bottom: 50px !important
    }

    .mb-md-60 {
        margin-bottom: 60px !important
    }

    .mb-md-70 {
        margin-bottom: 70px !important
    }

    .mb-md-80 {
        margin-bottom: 80px !important
    }

    .mb-md-90 {
        margin-bottom: 90px !important
    }

    .mb-md-100 {
        margin-bottom: 100px !important
    }

    .mb-md-110 {
        margin-bottom: 110px !important
    }

    .mb-md-120 {
        margin-bottom: 120px !important
    }

    .mb-md-130 {
        margin-bottom: 130px !important
    }

    .mb-md-140 {
        margin-bottom: 140px !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-10 {
        padding-top: 10px !important
    }

    .pt-md-20 {
        padding-top: 20px !important
    }

    .pt-md-30 {
        padding-top: 30px !important
    }

    .pt-md-40 {
        padding-top: 40px !important
    }

    .pt-md-50 {
        padding-top: 50px !important
    }

    .pt-md-60 {
        padding-top: 60px !important
    }

    .pt-md-70 {
        padding-top: 70px !important
    }

    .pt-md-80 {
        padding-top: 80px !important
    }

    .pt-md-90 {
        padding-top: 90px !important
    }

    .pt-md-100 {
        padding-top: 100px !important
    }

    .pt-md-110 {
        padding-top: 110px !important
    }

    .pt-md-120 {
        padding-top: 120px !important
    }

    .pt-md-130 {
        padding-top: 130px !important
    }

    .pt-md-140 {
        padding-top: 140px !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-10 {
        padding-bottom: 10px !important
    }

    .pb-md-20 {
        padding-bottom: 20px !important
    }

    .pb-md-30 {
        padding-bottom: 30px !important
    }

    .pb-md-40 {
        padding-bottom: 40px !important
    }

    .pb-md-50 {
        padding-bottom: 50px !important
    }

    .pb-md-60 {
        padding-bottom: 60px !important
    }

    .pb-md-70 {
        padding-bottom: 70px !important
    }

    .pb-md-80 {
        padding-bottom: 80px !important
    }

    .pb-md-90 {
        padding-bottom: 90px !important
    }

    .pb-md-100 {
        padding-bottom: 100px !important
    }

    .pb-md-110 {
        padding-bottom: 110px !important
    }

    .pb-md-120 {
        padding-bottom: 120px !important
    }

    .pb-md-130 {
        padding-bottom: 130px !important
    }

    .pb-md-140 {
        padding-bottom: 140px !important
    }
}

@media only screen and (max-width:767px) {
    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-10 {
        margin-top: 10px !important
    }

    .mt-sm-20 {
        margin-top: 20px !important
    }

    .mt-sm-30 {
        margin-top: 30px !important
    }

    .mt-sm-40 {
        margin-top: 40px !important
    }

    .mt-sm-50 {
        margin-top: 50px !important
    }

    .mt-sm-60 {
        margin-top: 60px !important
    }

    .mt-sm-70 {
        margin-top: 70px !important
    }

    .mt-sm-80 {
        margin-top: 80px !important
    }

    .mt-sm-90 {
        margin-top: 90px !important
    }

    .mt-sm-100 {
        margin-top: 100px !important
    }

    .mt-sm-110 {
        margin-top: 110px !important
    }

    .mt-sm-120 {
        margin-top: 120px !important
    }

    .mt-sm-130 {
        margin-top: 130px !important
    }

    .mt-sm-140 {
        margin-top: 140px !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-10 {
        margin-bottom: 10px !important
    }

    .mb-sm-20 {
        margin-bottom: 20px !important
    }

    .mb-sm-30 {
        margin-bottom: 30px !important
    }

    .mb-sm-40 {
        margin-bottom: 40px !important
    }

    .mb-sm-50 {
        margin-bottom: 50px !important
    }

    .mb-sm-60 {
        margin-bottom: 60px !important
    }

    .mb-sm-70 {
        margin-bottom: 70px !important
    }

    .mb-sm-80 {
        margin-bottom: 80px !important
    }

    .mb-sm-90 {
        margin-bottom: 90px !important
    }

    .mb-sm-100 {
        margin-bottom: 100px !important
    }

    .mb-sm-110 {
        margin-bottom: 110px !important
    }

    .mb-sm-120 {
        margin-bottom: 120px !important
    }

    .mb-sm-130 {
        margin-bottom: 130px !important
    }

    .mb-sm-140 {
        margin-bottom: 140px !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-10 {
        padding-top: 10px !important
    }

    .pt-sm-20 {
        padding-top: 20px !important
    }

    .pt-sm-30 {
        padding-top: 30px !important
    }

    .pt-sm-40 {
        padding-top: 40px !important
    }

    .pt-sm-50 {
        padding-top: 50px !important
    }

    .pt-sm-60 {
        padding-top: 60px !important
    }

    .pt-sm-70 {
        padding-top: 70px !important
    }

    .pt-sm-80 {
        padding-top: 80px !important
    }

    .pt-sm-90 {
        padding-top: 90px !important
    }

    .pt-sm-100 {
        padding-top: 100px !important
    }

    .pt-sm-110 {
        padding-top: 110px !important
    }

    .pt-sm-120 {
        padding-top: 120px !important
    }

    .pt-sm-130 {
        padding-top: 130px !important
    }

    .pt-sm-140 {
        padding-top: 140px !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-10 {
        padding-bottom: 10px !important
    }

    .pb-sm-20 {
        padding-bottom: 20px !important
    }

    .pb-sm-30 {
        padding-bottom: 30px !important
    }

    .pb-sm-40 {
        padding-bottom: 40px !important
    }

    .pb-sm-50 {
        padding-bottom: 50px !important
    }

    .pb-sm-60 {
        padding-bottom: 60px !important
    }

    .pb-sm-70 {
        padding-bottom: 70px !important
    }

    .pb-sm-80 {
        padding-bottom: 80px !important
    }

    .pb-sm-90 {
        padding-bottom: 90px !important
    }

    .pb-sm-100 {
        padding-bottom: 100px !important
    }

    .pb-sm-110 {
        padding-bottom: 110px !important
    }

    .pb-sm-120 {
        padding-bottom: 120px !important
    }

    .pb-sm-130 {
        padding-bottom: 130px !important
    }

    .pb-sm-140 {
        padding-bottom: 140px !important
    }
}

@media only screen and (max-width:575px) {
    .mt-xs-0 {
        margin-top: 0 !important
    }

    .mt-xs-10 {
        margin-top: 10px !important
    }

    .mt-xs-20 {
        margin-top: 20px !important
    }

    .mt-xs-30 {
        margin-top: 30px !important
    }

    .mt-xs-40 {
        margin-top: 40px !important
    }

    .mt-xs-50 {
        margin-top: 50px !important
    }

    .mt-xs-60 {
        margin-top: 60px !important
    }

    .mt-xs-70 {
        margin-top: 70px !important
    }

    .mt-xs-80 {
        margin-top: 80px !important
    }

    .mt-xs-90 {
        margin-top: 90px !important
    }

    .mt-xs-100 {
        margin-top: 100px !important
    }

    .mt-xs-110 {
        margin-top: 110px !important
    }

    .mt-xs-120 {
        margin-top: 120px !important
    }

    .mt-xs-130 {
        margin-top: 130px !important
    }

    .mt-xs-140 {
        margin-top: 140px !important
    }

    .mb-xs-0 {
        margin-bottom: 0 !important
    }

    .mb-xs-10 {
        margin-bottom: 10px !important
    }

    .mb-xs-20 {
        margin-bottom: 20px !important
    }

    .mb-xs-30 {
        margin-bottom: 30px !important
    }

    .mb-xs-40 {
        margin-bottom: 40px !important
    }

    .mb-xs-50 {
        margin-bottom: 50px !important
    }

    .mb-xs-60 {
        margin-bottom: 60px !important
    }

    .mb-xs-70 {
        margin-bottom: 70px !important
    }

    .mb-xs-80 {
        margin-bottom: 80px !important
    }

    .mb-xs-90 {
        margin-bottom: 90px !important
    }

    .mb-xs-100 {
        margin-bottom: 100px !important
    }

    .mb-xs-110 {
        margin-bottom: 110px !important
    }

    .mb-xs-120 {
        margin-bottom: 120px !important
    }

    .mb-xs-130 {
        margin-bottom: 130px !important
    }

    .mb-xs-140 {
        margin-bottom: 140px !important
    }

    .pt-xs-0 {
        padding-top: 0 !important
    }

    .pt-xs-10 {
        padding-top: 10px !important
    }

    .pt-xs-20 {
        padding-top: 20px !important
    }

    .pt-xs-30 {
        padding-top: 30px !important
    }

    .pt-xs-40 {
        padding-top: 40px !important
    }

    .pt-xs-50 {
        padding-top: 50px !important
    }

    .pt-xs-60 {
        padding-top: 60px !important
    }

    .pt-xs-70 {
        padding-top: 70px !important
    }

    .pt-xs-80 {
        padding-top: 80px !important
    }

    .pt-xs-90 {
        padding-top: 90px !important
    }

    .pt-xs-100 {
        padding-top: 100px !important
    }

    .pt-xs-110 {
        padding-top: 110px !important
    }

    .pt-xs-120 {
        padding-top: 120px !important
    }

    .pt-xs-130 {
        padding-top: 130px !important
    }

    .pt-xs-140 {
        padding-top: 140px !important
    }

    .pb-xs-0 {
        padding-bottom: 0 !important
    }

    .pb-xs-10 {
        padding-bottom: 10px !important
    }

    .pb-xs-20 {
        padding-bottom: 20px !important
    }

    .pb-xs-30 {
        padding-bottom: 30px !important
    }

    .pb-xs-40 {
        padding-bottom: 40px !important
    }

    .pb-xs-50 {
        padding-bottom: 50px !important
    }

    .pb-xs-60 {
        padding-bottom: 60px !important
    }

    .pb-xs-70 {
        padding-bottom: 70px !important
    }

    .pb-xs-80 {
        padding-bottom: 80px !important
    }

    .pb-xs-90 {
        padding-bottom: 90px !important
    }

    .pb-xs-100 {
        padding-bottom: 100px !important
    }

    .pb-xs-110 {
        padding-bottom: 110px !important
    }

    .pb-xs-120 {
        padding-bottom: 120px !important
    }

    .pb-xs-130 {
        padding-bottom: 130px !important
    }

    .pb-xs-140 {
        padding-bottom: 140px !important
    }
}

@media only screen and (max-width:480px) {
    .mt-xxs-0 {
        margin-top: 0 !important
    }

    .mt-xxs-10 {
        margin-top: 10px !important
    }

    .mt-xxs-20 {
        margin-top: 20px !important
    }

    .mt-xxs-30 {
        margin-top: 30px !important
    }

    .mt-xxs-40 {
        margin-top: 40px !important
    }

    .mt-xxs-50 {
        margin-top: 50px !important
    }

    .mt-xxs-60 {
        margin-top: 60px !important
    }

    .mt-xxs-70 {
        margin-top: 70px !important
    }

    .mt-xxs-80 {
        margin-top: 80px !important
    }

    .mt-xxs-90 {
        margin-top: 90px !important
    }

    .mt-xxs-100 {
        margin-top: 100px !important
    }

    .mt-xxs-110 {
        margin-top: 110px !important
    }

    .mt-xxs-120 {
        margin-top: 120px !important
    }

    .mt-xxs-130 {
        margin-top: 130px !important
    }

    .mt-xxs-140 {
        margin-top: 140px !important
    }

    .mb-xxs-0 {
        margin-bottom: 0 !important
    }

    .mb-xxs-10 {
        margin-bottom: 10px !important
    }

    .mb-xxs-20 {
        margin-bottom: 20px !important
    }

    .mb-xxs-30 {
        margin-bottom: 30px !important
    }

    .mb-xxs-40 {
        margin-bottom: 40px !important
    }

    .mb-xxs-50 {
        margin-bottom: 50px !important
    }

    .mb-xxs-60 {
        margin-bottom: 60px !important
    }

    .mb-xxs-70 {
        margin-bottom: 70px !important
    }

    .mb-xxs-80 {
        margin-bottom: 80px !important
    }

    .mb-xxs-90 {
        margin-bottom: 90px !important
    }

    .mb-xxs-100 {
        margin-bottom: 100px !important
    }

    .mb-xxs-110 {
        margin-bottom: 110px !important
    }

    .mb-xxs-120 {
        margin-bottom: 120px !important
    }

    .mb-xxs-130 {
        margin-bottom: 130px !important
    }

    .mb-xxs-140 {
        margin-bottom: 140px !important
    }

    .pt-xxs-0 {
        padding-top: 0 !important
    }

    .pt-xxs-10 {
        padding-top: 10px !important
    }

    .pt-xxs-20 {
        padding-top: 20px !important
    }

    .pt-xxs-30 {
        padding-top: 30px !important
    }

    .pt-xxs-40 {
        padding-top: 40px !important
    }

    .pt-xxs-50 {
        padding-top: 50px !important
    }

    .pt-xxs-60 {
        padding-top: 60px !important
    }

    .pt-xxs-70 {
        padding-top: 70px !important
    }

    .pt-xxs-80 {
        padding-top: 80px !important
    }

    .pt-xxs-90 {
        padding-top: 90px !important
    }

    .pt-xxs-100 {
        padding-top: 100px !important
    }

    .pt-xxs-110 {
        padding-top: 110px !important
    }

    .pt-xxs-120 {
        padding-top: 120px !important
    }

    .pt-xxs-130 {
        padding-top: 130px !important
    }

    .pt-xxs-140 {
        padding-top: 140px !important
    }

    .pb-xxs-0 {
        padding-bottom: 0 !important
    }

    .pb-xxs-10 {
        padding-bottom: 10px !important
    }

    .pb-xxs-20 {
        padding-bottom: 20px !important
    }

    .pb-xxs-30 {
        padding-bottom: 30px !important
    }

    .pb-xxs-40 {
        padding-bottom: 40px !important
    }

    .pb-xxs-50 {
        padding-bottom: 50px !important
    }

    .pb-xxs-60 {
        padding-bottom: 60px !important
    }

    .pb-xxs-70 {
        padding-bottom: 70px !important
    }

    .pb-xxs-80 {
        padding-bottom: 80px !important
    }

    .pb-xxs-90 {
        padding-bottom: 90px !important
    }

    .pb-xxs-100 {
        padding-bottom: 100px !important
    }

    .pb-xxs-110 {
        padding-bottom: 110px !important
    }

    .pb-xxs-120 {
        padding-bottom: 120px !important
    }

    .pb-xxs-130 {
        padding-bottom: 130px !important
    }

    .pb-xxs-140 {
        padding-bottom: 140px !important
    }
}

.p-0 {
    padding: 0 !important
}