.profile__image_file::-webkit-file-upload-button {
    visibility: hidden;
}

.profile__image_file::before {
    background-color: #000;
    content: "Select image "url(http://localhost:3000/static/media/edit.8667ac4a523e8fc42e59.svg);
    color: #fff;
    display: inline-block;
    border-radius: 6px;
    padding: 8px 12px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.profile__image_file:hover:before {
    opacity: 0.8;
}

.profile_output_image {
    height: 70px !important;
    width: auto;
}