.sticky-footer {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    bottom: 0;
    width: 100%;
    padding: 15px 0 15px 0;
    background: #fff;
    -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 18px;
}

.sticky-footer__colour-icon {
    cursor: pointer;
}