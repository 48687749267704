.popup {
    min-width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-family: "Inter", Arial, sans-serif;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s, opacity 0.2s linear;
    -o-transition: visibility 0s, opacity 0.2s linear;
    transition: visibility 0s, opacity 0.2s linear;
    z-index: 9999;
}

.popup_opened {
    visibility: visible;
    opacity: 1;
}

.popup__overlay {
    min-width: 100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
}

.popup__form {
    margin: auto auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 430px;
    width: calc(100% - 38px);
    padding: 34px 36px 36px 36px;
    background-color: #fff;
    position: relative;
    -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.popup__close-btn {
    width: 40px;
    height: 40px;
    position: absolute;
    padding: 0;
    border: none;
    right: -40px;
    top: -40px;
    background-color: transparent;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.popup__close-btn:hover {
    opacity: 0.6;
    cursor: pointer;
}

.popup__close-icon {
    width: 100%;
    height: auto;
}

.popup__form-label {
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    margin: 0px 0 15px;
}

.popup__form-input {
    max-width: 358px;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding: 13px 0;
    margin-bottom: 17px;
    color: #000000;
}

.popup__form-input:hover {
    border-bottom-color: #000;
}

.popup__form-input:focus {
    border-bottom-color: #000;
}

.popup__form-input:valid {
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

.popup__form-input:invalid {
    border-bottom: 1px solid red;
}

.popup__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.popup__social-icon {
    margin: 9px 14px 0 0;
}

.popup__form-textarea:hover {
    border-bottom-color: #000;
}

.popup__form-textarea:valid {
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

.popup__form-textarea:invalid {
    border: 1px solid red;
}

.popup__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.popup__social-icon {
    margin: 9px 14px 0 0;
}

.popup__save-btn {
    width: 100%;
    background-color: #000000;
    border: none;
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #fff;
    padding: 14px 0 18px;
    margin: 31px auto 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.popup__save-btn:hover {
    opacity: 0.8;
    cursor: pointer;
}

.popup__save-btn-disabled {
    background-color: #cfcfcf;
}

.popup__form_image {
    background: none;
    max-width: 75vw;
    max-height: 75vh;
    width: auto;
    padding: 0;
    margin: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.popup__form-textarea {
    min-height: 200px;
    max-height: 500px;
    width: 100%;
    max-width: 900px;
    border: 1px solid #eee;
}

.popup__form-textarea::-webkit-input-placeholder {
    font-size: 1rem;
    color: #9c9c9c;
    font-family: "Roboto", sans-serif;
}

.popup__form-textarea::-moz-placeholder {
    font-size: 1rem;
    color: #9c9c9c;
    font-family: "Roboto", sans-serif;
}

.popup__form-textarea:-ms-input-placeholder {
    font-size: 1rem;
    color: #9c9c9c;
    font-family: "Roboto", sans-serif;
}

.popup__form-textarea::-ms-input-placeholder {
    font-size: 1rem;
    color: #9c9c9c;
    font-family: "Roboto", sans-serif;
}

.popup__form-textarea::placeholder {
    font-size: 1rem;
    color: #9c9c9c;
    font-family: "Roboto", sans-serif;
}

.popup__card-image-preview {
    width: 100%;
    max-width: 75vw;
    max-height: 75vh;
}

.popup__card-image-preview-name {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    color: #fff;
}

.popup__form-input_type_link_id,
.popup__form-input_type_file_id {
    height: 0;
    padding: 0;
    border: none;
    margin: 0;
    width: 0;
    visibility: hidden;
    opacity: 0;
}

.popup__footer-close-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    padding: 0;
    border: none;
    right: 14px;
    top: 16px;
    background-color: transparent;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.popup__footer-close-btn .popup__close-icon {
    -webkit-filter: invert(1);
    filter: invert(1);
}

@media screen and (max-width: 540px) {
    .popup__form {
        padding: 25px 22px 25px 22px;
    }

    .popup__form_image {
        padding: 0;
    }

    .popup__close-btn {
        right: -7px;
        top: -40px;
        -webkit-animation: spin 0.3s;
        animation: spin 0.3s;
    }

    .popup__close-icon {
        width: 26px;
        height: 26px;
    }
}

@media screen and (max-height: 753px) {
    .popup__edit-social-form {
        overflow-y: scroll;
        height: 66vh;
    }
}

@media screen and (max-height: 620px) {
    .popup__edit-profile {
        overflow-y: scroll;
        height: 50vh;
    }
}

@media screen and (max-height: 530px) {
    .popup__edit-about-form {
        overflow-y: scroll;
        height: 40vh;
    }

    .popup__edit-social-form {
        overflow-y: scroll;
        height: 40vh;
    }

    .popup__form-label {
        margin: 0 0 17px;
    }
}


@media screen and (max-width: 540px) {
    .popup__footer-close-btn {
        right: 13px;
        top: 11px;
        -webkit-animation: spin 0.3s;
        animation: spin 0.3s;
    }
}

.userinfo {
    background: #fff;
    position: fixed;
    width: 100%;
    height: 0;
    bottom: 0;
    -webkit-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    left: 50%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    overflow: hidden;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.userinfo_opened {
    height: 465px;
}

@media screen and (max-width: 540px) {
    .userinfo_opened {
        height: 537px;
    }
}

@media screen and (max-height: 626px) {
    .userinfo_opened {
        height: 368px;
        overflow: scroll;
    }
}