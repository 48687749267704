.files {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  overflow: hidden;
  background: #f7f7f7;
  -webkit-box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
}

.files__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: baseline;
}

.files__text {
  margin: 0;
  text-decoration: none;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.files__edit-icons {
  position: relative;
  z-index: -1;
}

.files__image-container {
  width: 60px;
  height: 60px;
}

.files__edit-open,
.files__edit-close {
  position: relative;
  z-index: 0;
  cursor: pointer;
}

.files__icon {
  padding: 19px 19px 17px 23px;
  border-radius: 7px;
  cursor: pointer;
  font-size: x-large;
}

.files_right-arrow {
  margin-left: auto;
  padding-right: 20px;
  font-size: 20px;
  cursor: pointer;
  color: red;
}

.files__delete {
  position: absolute;
  left: 30px;
  top: 87px;
  padding: 24px 24px 24px 24px;
  border-radius: 7px;
  cursor: pointer;
}

.files__delete-icon {
  position: relative;
  z-index: -1;
}

.files__heading {
  margin-bottom: 30px;
}

.files__edit-btn {
  position: relative;
  background-image: url('../../images/icons/edit.svg');
  background-color: transparent;
  background-position: center;
  border: none;
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  cursor: pointer;
  padding: 15px;
  margin-right: -10px;
  filter: invert(0.5);
}

.files__add {
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s linear;
  transition: all 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  max-height: 0;
  transform-origin: top;
  transform: scaleY(0);
  overflow: hidden;
  border: none;
  background: transparent;
}

.files__add:hover {
  opacity: 0.8;
}

.files__add_visible {
  transform: scaleY(1);
  overflow: visible;
  max-height: fit-content;
  position: relative;
}

@media only screen and (max-width: 480px) {
  .files__heading {
    margin-bottom: 10px;
  }
}