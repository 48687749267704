.buttons__container {
    padding: 0;
    background: #fff;
    padding-top: 222px;
    margin-top: -176px;
    transition: 0.03s;
}

.buttons__share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 10px;
}

.buttons__share_big {
    width: 100%;
}

.buttons__share_md {
    width: 80%;
}

.buttons__share_orange.btn-mod {
    color: #fff;
    border: none;
    background: #dc3545;
}

.buttons__share .btn-mod.btn-round {
    border-radius: 6px;
}


@media only screen and (max-width: 1318px) {
    .buttons__container {
        margin-top: -18vmin;
        padding-top: 22.5vmin;
    }
}

@media only screen and (max-width: 980px) {
    .buttons__container {
        margin-top: -100px;
        padding-top: 140px;
    }
}

@media only screen and (max-width: 767px) {
    .buttons__share_orange.btn-mod {
        padding: 12px 10px 12px 10px;
    }

    .buttons__container {
        margin-top: -76px;
        padding-top: 113px;
    }
}