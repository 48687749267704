.link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  gap: 16px;
  border-radius: 6px;
  overflow: hidden;
  background: #f7f7f7;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.link:hover {
  background: #f8f9fa;
}

.link__image-container {
  position: relative;
  width: 60px;
  height: 60px;
}

.link__image-container img:not([draggable]) {
  height: 100%;
}

.link__image {
  width: 100%;
  -o-object-fit: cover;
  object-fit: contain;
}

.link__text {
  color: #000;
  margin: 0;
  text-decoration: underline transparent;
  transition: text-decoration 0.2s linear;
}

.link__text:hover{
  text-decoration: underline;

}

.link__delete {
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 24px;
  background: #00000066;
  color: white;
  border-radius: 0px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s linear;
  visibility: hidden;
}

.link__delete_visible {
  visibility: visible;
  opacity: 1;
}

.link__right-arrow {
  margin-left: auto;
  padding-right: 20px;
  font-size: 20px;
  cursor: pointer;
  color: #dc3545;
  visibility: visible;
  opacity: 1;
  z-index: 0;
}

.link__right-arrow_visible {
  transition: all 0.3s linear;
  visibility: hidden;
}

.link__edit-icons {
  position: relative;
  z-index: -1;
}