.social__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: baseline;
}

.social__heading {
    margin-bottom: 30px;
}

.social__edit_dark {
    -webkit-filter: invert(0.5);
    filter: invert(0.5);
    padding: 4px 8px 4px 8px;
    cursor: pointer;
}

.social-links a {
    width: 54px;
    height: 54px;
    line-height: 54px !important;
    position: relative;
    margin: 0 2px;
    text-align: center;
    display: inline-block;
    color: #dc3545;
    font-size: 24px;
    opacity: .85;
    overflow: hidden;
    -webkit-transition: all 0.23s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.23s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.23s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.social-links a:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #f8f9fa;
    top: 0;
    left: 0;
    border-radius: 10px;
    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    z-index: -1;
}

.social-links a:hover:before {
    background: #ced4da;
}

@media only screen and (max-width: 480px) .about__heading {
    .social__heading {
        margin-bottom: 10px;
    }
}