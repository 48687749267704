.links__input {
  max-width: 358px;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 13px 0;
  margin-bottom: 17px;
  color: #000000;
}

.links__input:invalid{
  border-bottom: 1px solid #dc3545;
}

.links__input_file::-webkit-file-upload-button{
  visibility: hidden;
}

.links__input_file::before{
  background-color: #000;
  content: 'Select some files';
  color: #fff;
  display: inline-block;
  border-radius: 6px;
  padding: 8px 12px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  transition: all 0.3s;
}

.links__input_file:hover:before{
  opacity: 0.8;
}