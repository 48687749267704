@charset "utf-8";
@import "./vendor/all.min.css";
@import url("./vendor/vertical-rhythm.min.css");

@font-face {
  font-family: "HK_Grotesk";
  src: url("./webfonts/hkgrotesk-regular-webfont.woff2") format("woff2"),
    url("./webfonts/hkgrotesk-regular-webfont.woff") format("woff");
  font-weights: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HK_Grotesk_alt";
  src: url("./webfonts/hkgrotesk-regular-webfont-alt.woff2") format("woff2"),
    url("./webfonts/hkgrotesk-regular-webfont-alt.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HK_Grotesk";
  src: url("./webfonts/hkgrotesk-italic-webfont.woff2") format("woff2"),
    url("./webfonts/hkgrotesk-italic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "HK_Grotesk";
  src: url("./webfonts/hkgrotesk-semibold-webfont.woff2") format("woff2"),
    url("./webfonts/hkgrotesk-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: white;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #161616;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 0 solid white;
  /* creates padding around scroll thumb */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  scroll-behavior: auto;
  --font-global: HK_Grotesk, arial, sans-serif;
  --font-global-alt: HK_Grotesk_alt, arial, sans-serif;
}

html {
  overflow-y: scroll;
  -ms-overflow-style: scrollbar;
}

html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

iframe {
  border: none;
}

a,
b,
div,
ul,
li {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -moz-outline-: none;
}

a:focus:not(.focus-visible),
a:active,
section:focus,
div:active,
div:focus {
  -moz-outline: none;
  outline: none;
}

a:focus.focus-visible,
button:focus.focus-visible,
div:focus.focus-visible {
  -moz-outline: 2px dotted #36a367 !important;
  outline: 2px dotted #36a367 !important;
  outline-offset: 0 !important;
}


.min-height-80vh {
  min-height: 80vh;
}

.min-height-90vh {
  min-height: 90vh;
}

.min-height-100vh {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

.align-center {
  text-align: center !important;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.bg-position-top {
  background-position-y: top !important;
}

.bg-position-bottom {
  background-position-y: bottom !important;
}

.relative {
  position: relative;
}

.hidden {
  display: none;
}

.inline-block {
  display: inline-block;
}

.overflow-hidden {
  overflow: hidden;
}

.white {
  color: #fff !important;
}

.gray {
  color: #757575 !important;
}

.light-content .gray {
  color: #bbb !important;
}

.black {
  color: #111 !important;
}

.color {
  color: #f1273c !important;
}

.image-fullwidth img {
  width: 100%;
  height: auto;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-015 {
  opacity: 0.15;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-025 {
  opacity: 0.25;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-035 {
  opacity: 0.35;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-045 {
  opacity: 0.45;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-055 {
  opacity: 0.55;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-065 {
  opacity: 0.65;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-075 {
  opacity: 0.75;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-085 {
  opacity: 0.85;
}

.opacity-09 {
  opacity: 0.9;
}

.opacity-095 {
  opacity: 0.95;
}

.opacity-1 {
  opacity: 1;
}

.stick-fixed {
  position: fixed !important;
  top: 0;
  left: 0;
}

.round {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
}

::-moz-selection {
  color: #fff;
  background: #000;
}

::-webkit-selection {
  color: #fff;
  background: #000;
}

::selection {
  color: #fff;
  background: #000;
}

.animate,
.animate * {
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
}

.no-animate {
  -webkit-transition-property: none !important;
  -moz-transition-property: none !important;
  -o-transition-property: none !important;
  transition-property: none !important;
}

.clearlist,
.clearlist li {
  list-style: none;
  padding: 0;
  margin: 0;
  background: none;
}

.full-wrapper {
  margin: 0 2%;
}

.page-loader {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fefefe;
  z-index: 100000;
}

.loader {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  font-size: 10px;
  text-indent: -12345px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.55);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: spinner 700ms infinite linear;
  -moz-animation: spinner 700ms infinite linear;
  -ms-animation: spinner 700ms infinite linear;
  -o-animation: spinner 700ms infinite linear;
  animation: spinner 700ms infinite linear;
  will-change: transform;
  z-index: 100001;
}

.page-loader.dark {
  background: #111;
}

.page-loader.dark .loader {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: spinner 700ms infinite linear;
  -moz-animation: spinner 700ms infinite linear;
  animation: spinner 700ms infinite linear;
  z-index: 100001;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

body {
  color: #111;
  font-family: var(--font-global);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.00208em;
  line-height: 1.61;
}

a {
  color: #111;
  text-decoration: underline;
  -webkit-transition: color 0.1s cubic-bezier(0, 0, 0.58, 1);
  transition: color 0.1s cubic-bezier(0, 0, 0.58, 1);
}

a:hover {
  color: #555;
  text-decoration: underline;
}

b,
strong {
  font-weight: 600;
}

mark {
  position: relative;
  padding: 0;
  color: inherit;
  background-color: transparent;
  background-image: linear-gradient(90deg,
      rgba(17, 17, 17, 0.1) 0,
      rgba(17, 17, 17, 0.1) 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.3em;
  background-position: 0 110%;
}

.light-content mark {
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0.2) 0,
      rgba(255, 255, 255, 0.2) 100%);
}

.small,
small {
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 1em;
  font-weight: 600;
  line-height: 1.2;
}

h1,
.h1 {
  margin-bottom: 0.5em;
  font-size: 3.25rem;
  letter-spacing: -0.04em;
}

h2,
.h2 {
  margin-bottom: 0.5em;
  font-size: 2.875rem;
  letter-spacing: -0.04em;
}

h3,
.h3 {
  font-size: 2.17rem;
  letter-spacing: -0.04em;
}

h4,
.h4 {
  font-size: 1.5rem;
  letter-spacing: -0.04em;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
  font-weight: 700;
}

p {
  margin: 0 0 1.5em 0;
}

ul,
ol {
  margin: 0 0 1.5em 0;
}

blockquote {
  margin: 0 0 3em 0;
  padding: 0;
  border: none;
  background: none;
  font-style: normal;
  letter-spacing: -0.02em;
  line-height: 1.6;
}

blockquote p {
  position: relative;
  margin-bottom: 1em;
  font-size: 26px;
  font-style: normal;
  letter-spacing: -0.02em;
  line-height: 1.6;
}

blockquote footer {
  margin-top: -10px;
  font-size: 15px;
  font-weight: 400;
}

dl dt {
  font-weight: 600;
}

pre {
  background-color: #f9f9f9;
  border-color: #ddd;
}

hr {
  background-color: #000;
  opacity: 0.1;
}

hr.white {
  background-color: #fff;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.0454545em;
}

.serif {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-weight: normal;
}

.normal {
  font-weight: normal;
}

.strong {
  font-weight: 700 !important;
}

.lead {
  margin: 1em 0 2em 0;
  font-size: 140%;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6;
}

.lead-alt {
  font-size: 46px;
  font-weight: 600;
  line-height: 1.196;
  letter-spacing: -0.04em;
}

.text {
  font-size: 16px;
  color: #5f5f5f;
  line-height: 1.8;
}

.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
  color: #111;
}

.light-content .text {
  font-weight: 400;
  color: #fff;
}

.light-content .text h1,
.light-content .text h2,
.light-content .text h3,
.light-content .text h4,
.light-content .text h5,
.light-content .text h6 {
  color: #fff;
}

.dropcap {
  float: left;
  font-size: 3em;
  line-height: 1;
  padding: 2px 8px 2px 0;
}

.table-bordered th,
.table-bordered td {
  border-color: #e0e0e0;
}

.light-content table {
  color: #fff;
}

.light-content .table-bordered th,
.light-content .table-bordered td {
  border-color: #333;
}

.light-content .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.highlight pre {
  border: 1px solid #eaeaea;
  background: #fcfcfc;
  color: #555;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.light-content .highlight pre {
  border-color: transparent;
  background-color: #282828;
  color: #bbb;
}

.big-icon {
  display: block;
  width: 90px;
  height: 90px;
  margin: 0 auto 20px;
  border: 3px solid #111;
  text-align: center;
  line-height: 81px;
  font-size: 32px;
  color: #111;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
}

.big-icon .fa-play {
  position: relative;
  left: 4px;
}

.big-icon-link {
  display: inline-block;
  text-decoration: none;
  -webkit-transform: scale(1.0001);
  transform: scale(1.0001);
}

.big-icon-link:hover .big-icon {
  -webkit-transition: 0 none !important;
  transition: 0 none !important;
}

.big-icon-link:hover .big-icon {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.big-icon-link:active .big-icon {
  -webkit-transform: scale(0.99);
  transform: scale(0.99);
}

.big-icon-link:hover {
  text-decoration: none;
}

.light-content .big-icon {
  color: #fff;
  border-color: #fff;
}

.btn-mod,
a.btn-mod {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 3px 13px 4px;
  color: #fff;
  background: rgba(17, 17, 17, 0.9);
  border: 2px solid transparent;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.065em;
  border-radius: 0;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
}

.btn-mod:hover,
.btn-mod:focus,
a.btn-mod:hover,
a.btn-mod:focus {
  background: #000;
  color: #fff;
  text-decoration: none;
  outline: none;
  border-color: transparent;
  box-shadow: none;
}

.btn-mod:active {
  cursor: pointer !important;
  outline: none !important;
}

.btn-hover-up:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.btn-mod.btn-small {
  height: auto;
  padding: 7px 17px 8px;
  font-size: 12px;
  letter-spacing: 1px;
}

.btn-mod.btn-medium {
  height: auto;
  padding: 11px 37px 12px;
  font-size: 14px;
}

.btn-mod.btn-large {
  height: auto;
  padding: 14px 38px 15px;
  font-size: 15px;
}

.btn-mod.btn-glass {
  color: rgba(255, 255, 255, 0.75);
  background: rgba(0, 0, 0, 0.4);
}

.btn-mod.btn-glass:hover,
.btn-mod.btn-glass:focus {
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 1);
}

.btn-mod.btn-border {
  color: #151515;
  border: 2px solid #151515;
  background: transparent;
}

.btn-mod.btn-border:hover,
.btn-mod.btn-border:focus {
  color: #fff;
  border-color: transparent;
  background: #000;
}

.btn-mod.btn-border-c {
  color: #f1273c;
  border: 2px solid #f1273c;
  background: transparent;
}

.btn-mod.btn-border-c:hover,
.btn-mod.btn-border-c:focus {
  color: #fff;
  border-color: transparent;
  background: #f1273c;
}

.btn-mod.btn-border-w {
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.75);
  background: transparent;
}

.btn-mod.btn-border-w:hover,
.btn-mod.btn-border-w:focus {
  color: #111;
  border-color: transparent;
  background: #fff;
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
}

.btn-mod.btn-w {
  color: #111;
  background: rgba(255, 255, 255, 0.9);
}

.btn-mod.btn-w:hover,
.btn-mod.btn-w:focus {
  color: #111;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
}

.btn-mod.btn-w-color {
  color: #f1273c;
  background: #fff;
}

.btn-mod.btn-w-color:hover,
.btn-mod.btn-w-color:focus {
  color: #151515;
  background: #fff;
}

.btn-mod.btn-gray {
  color: #707070;
  background: #e5e5e5;
}

.btn-mod.btn-gray:hover,
.btn-mod.btn-gray:focus {
  color: #333;
  background: #d5d5d5;
}

.btn-mod.btn-color {
  color: #fff;
  background: #f1273c;
}

.btn-mod.btn-color:hover,
.btn-mod.btn-color:focus {
  color: #fff;
  background: #f1273c;
  opacity: 0.85;
}

.btn-mod.btn-circle {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.btn-mod.btn-round {
  border-radius: 3px;
}

.btn-icon {
  position: relative;
  border: none;
  overflow: hidden;
}

.btn-icon.btn-small {
  overflow: hidden;
}

.btn-icon>span {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  left: 0;
  color: #777;
  font-size: 48px;
  opacity: 0.2;
  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
}

.btn-icon:hover>span {
  opacity: 0;
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  transform: scale(2);
}

.btn-icon>span.white {
  color: #fff;
}

.btn-icon>span.black {
  color: #000;
}

.btn-full {
  width: 100%;
}



.logo,
a.logo:hover {
  font-size: 18px;
  font-weight: 600 !important;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
}

.light-content .mn-sub {
  background: rgba(50, 50, 50, 0.9927);
}


.page-section {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;
}



.bg-white {
  background-color: #fff;
}

.bg-gray {
  background-color: #f1f1f1;
}

.bg-gray-lighter {
  background-color: #f7f7f7;
}

.bg-gray-dark {
  background-color: #282828;
}

.light-content {
  color: #ffffff;
}

.bg-dark {
  background-color: #111 !important;
}

.bg-dark-lighter {
  background-color: #1b1b1b !important;
}

.bg-dark.light-content {
  background-color: #1b1b1b !important;
}

.bg-dark-lighter.light-content {
  background-color: #232323 !important;
}

.bg-dark,
.bg-dark-lighter,
.bg-dark-alfa-30,
.bg-dark-alfa-50,
.bg-dark-alfa-70,
.bg-dark-alfa-90,
.bg-dark-alfa,
.bg-color,
.bg-color-lighter,
.bg-color-alfa-30,
.bg-color-alfa-50,
.bg-color-alfa-70,
.bg-color-alfa-90,
.bg-color-alfa {
  color: #fff;
}

.bg-dark a,
.bg-dark-lighter a,
.bg-dark-alfa-30 a,
.bg-dark-alfa-50 a,
.bg-dark-alfa-70 a,
.bg-dark-alfa-90 a,
.bg-dark-alfa a,
.bg-color a,
.bg-colorlighter a,
.bg-color-alfa-30 a,
.bg-color-alfa-50 a,
.bg-color-alfa-70 a,
.bg-color-alfa-90 a,
.bg-color-alfa a {
  color: rgba(255, 255, 255, 0.8);
}

.bg-dark a:hover,
.bg-dark-lighter a:hover,
.bg-dark-alfa-30 a:hover,
.bg-dark-alfa-50 a:hover,
.bg-dark-alfa-70 a:hover,
.bg-dark-alfa-90 a:hover,
.bg-dark-alfa a:hover,
.bg-color a:hover,
.bg-color-lighter a:hover,
.bg-color-alfa-30 a:hover,
.bg-color-alfa-50 a:hover,
.bg-color-alfa-70 a:hover,
.bg-color-alfa-90 a:hover,
.bg-color-alfa a:hover {
  color: #fff;
}

.bg-color .text,
.bg-color-lighter .text,
.bg-color-alfa-30 .text,
.bg-color-alfa-50 .text,
.bg-color-alfa-70 .text,
.bg-color-alfa-90 .text,
.bg-color-alfa .text,
.bg-color .section-text,
.bg-color-lighter .section-text,
.bg-color-alfa-30 .section-text,
.bg-color-alfa-50 .section-text,
.bg-color-alfa-70 .section-text,
.bg-color-alfa-90 .section-text,
.bg-color-alfa .section-text {
  color: #fff;
}

.bg-dark-alfa:before,
.bg-dark-alfa .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(17, 17, 17, 0.97);
}

.bg-dark-alfa-30:before,
.bg-dark-alfa-30 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(34, 34, 34, 0.3);
}

.bg-dark-alfa-50:before,
.bg-dark-alfa-50 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(34, 34, 34, 0.5);
}

.bg-dark-alfa-70:before,
.bg-dark-alfa-70 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(17, 17, 17, 0.7);
}

.bg-dark-alfa-90:before,
.bg-dark-alfa-90 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(3, 3, 3, 0.9);
}

.bg-light-alfa:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(252, 252, 252, 0.97);
}

.bg-light-alfa-30:before,
.bg-light-alfa-30 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(252, 252, 252, 0.3);
}

.bg-light-alfa-50:before,
.bg-light-alfa-50 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(252, 252, 252, 0.5);
}

.bg-light-alfa-70:before,
.bg-light-alfa-70 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(252, 252, 252, 0.7);
}

.bg-light-alfa-90:before,
.bg-light-alfa-90 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(252, 252, 252, 0.9);
}

.bg-color {
  background-color: #f1273c;
}

.bg-color-alfa:before,
.bg-color-alfa .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #f1273c;
  opacity: 0.97;
}

.bg-color-alfa-30:before,
.bg-color-alfa-30 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #f1273c;
  opacity: 0.3;
}

.bg-color-alfa-50:before,
.bg-color-alfa-50 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #f1273c;
  opacity: 0.5;
}

.bg-color-alfa-70:before,
.bg-color-alfa-70 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #f1273c;
  opacity: 0.7;
}

.bg-color-alfa-90:before,
.bg-color-alfa-90 .YTPOverlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #f1273c;
  opacity: 0.9;
}

.bg-orange {
  background-color: #f47631 !important;
}

.bg-yellow {
  background-color: #ffce02 !important;
}

.bg-cyan {
  background-color: #27c5c3 !important;
}

.bg-green {
  background-color: #58bf44 !important;
}

.bg-blue {
  background-color: #43a5de !important;
}

.bg-purple {
  background-color: #375acc !important;
}

.bg-red {
  background-color: #f1273c !important;
}

.light-content .section-title-descr {
  color: #bbb;
}

.light-content .section-more:after {
  background-color: #fff;
}

.section-heading {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5em;
  line-height: 1.3;
}

.section-line {
  width: 55px;
  height: 1px;
  margin: 30px auto;
  background: #111;
}

.section-text {
  font-weight: 400;
}

.light-content .section-text {
  font-weight: 400;
}

.section-text .lead-alt {
  margin-top: -0.2em;
}

.section-bot-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.section-bot-image img {
  display: block;
}

.image-fullwidth img {
  width: 100%;
}

.text-link {
  display: inline-block;
  padding-bottom: 2px;
  position: relative;
  color: #171717;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  opacity: 0.72;
  transition: all 0.17s cubic-bezier(0, 0, 0.58, 1);
}

.text-link:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  background-color: #171717;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  transform-origin: right center;
  -webkit-transition: transform 0.25s ease-out;
  -moz-transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
}

.text-link:hover {
  text-decoration: none;
  color: #171717;
  opacity: 1;
}

.text-link:hover:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.light-content .text-link:after {
  background-color: #fff;
}

.light-content .text-link {
  color: #fff;
  opacity: 0.9;
}


a.link-to-top {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 49px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  opacity: 0.9;
}

a.link-to-top:hover {
  opacity: 1;
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
}

.link-to-top-icon {
  display: inline-block;
  width: 13px;
  height: 9px;
  position: relative;
  top: -0.177em;
  opacity: 0.8;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13px" height="9px"><path fill-rule="evenodd" fill="rgb(17, 17, 17)" d="M13.004,7.374 L11.536,9.005 L6.550,3.095 L1.453,8.665 L-0.004,7.045 L6.133,0.336 L6.304,0.526 L6.780,-0.003 L13.004,7.374 Z"/></svg>');
}