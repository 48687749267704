.links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  gap: 16px;
  border-radius: 6px;
  overflow: hidden;
  background: #f7f7f7;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.Links__heading {
  margin-bottom: 30px;
}

.links:hover {
  background: #f8f9fa;
}

.links__image-container {
  width: 60px;
  height: 60px;
}

.links__image-container img:not([draggable]) {
  height: 100%;
}

.links__image {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.links__text {
  color: #000;
  margin: 0;
}

.links__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: baseline;
}

.links__edit_dark {
  -webkit-filter: invert(0.5);
  filter: invert(0.5);
  padding: 4px 8px 4px 8px;
}

a.link-to-top {
  bottom: 74px;
}

.links__add {
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s linear;
  transition: all 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  max-height: 0;
  transform-origin: top;
  transform: scaleY(0);
  overflow: hidden;
  border: none;
  background: transparent;
}

.links__add:hover {
  opacity: 0.8;
}

.links__add_visible {
  transform: scaleY(1);
  overflow: visible;
  max-height: fit-content;
  position: relative;
}

.links__output_image_add {
  height: 30px;
}

.links__edit-open {
  position: relative;
  background-image: url('../../images/icons/edit.svg');
  background-color: transparent;
  background-position: center;
  border: none;
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  cursor: pointer;
  padding: 15px;
  margin-right: -10px;
}

.links__edit-close {
  position: relative;
  z-index: 0;
  cursor: pointer;
}

.links__output_image_edit {
  height: 30px;
}

@media only screen and (max-width: 480px) {
  .links__heading {
    margin-bottom: 10px;
  }
}
